export const isLocalhost = () =>
  Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

export const isProductionEnvironment = () =>
  import.meta.env.REACT_APP_ENV?.includes("production");
export const isSandboxEnvironment = () =>
  import.meta.env.REACT_APP_ENV?.includes("sandbox");
export const isQAEnvironment = () =>
  import.meta.env.REACT_APP_ENV?.includes("qa");
export const isDevEnvironment = () =>
  import.meta.env.REACT_APP_ENV?.includes("development");
export const isStagingEnvironment = () =>
  import.meta.env.REACT_APP_ENV?.includes("staging");

export const isProduction = () => isProductionEnvironment() && !isLocalhost();
export const isSandbox = () => isSandboxEnvironment() && !isLocalhost();
export const shouldInitializeLogRocket = () => !isLocalhost();
export const shouldInitializeSentry = () => !isLocalhost();
export const shouldInitializeGoogleAnalytics = () => isProduction();
export const getFirestoreLogLevel = () => {
  if (isProduction() || isSandbox()) {
    return "silent";
  }

  return "error";
};

export const isHostedInEurope = () =>
  import.meta.env.REACT_APP_ENV?.includes("europe");

export const getZoomIntegrationName = () =>
  isHostedInEurope()
    ? "Rooster Europe"
    : "Kognitiv Rooster Interview Scheduler";

export const getWebexIntegrationName = () =>
  isHostedInEurope()
    ? "Rooster Europe"
    : "Kognitiv Rooster Interview Scheduler";

export const getRoosterExternalAppUrl = () => {
  if (isLocalhost()) {
    return "http://localhost:5173";
  }
  return import.meta.env.REACT_APP_ROOSTER_LINKS_EXTERNAL_URL;
};
