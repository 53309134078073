import * as Sentry from "@sentry/react";
import app from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/remote-config";
import LogRocket from "logrocket";
import { NOTAUTHORIZED } from "../../constants/routes";
import {
  getFirestoreLogLevel,
  shouldInitializeLogRocket
} from "../../runtimeConfig";
import { setCustomClaims } from "./helpers";

export const { FieldValue, Timestamp } = app.firestore;

const config = {
  apiKey: import.meta.env.REACT_APP_API_KEY,
  authDomain: import.meta.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: import.meta.env.REACT_APP_DATABASE_URL,
  projectId: import.meta.env.REACT_APP_PROJECT_ID,
  storageBucket: import.meta.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: import.meta.env.REACT_APP_APP_ID
};

class Firebase {
  constructor() {
    if (!app.apps.length) {
      app.initializeApp(config);
      app.firestore().settings({
        ignoreUndefinedProperties: true,
        experimentalAutoDetectLongPolling: true
      });
    }

    if (shouldInitializeLogRocket()) {
      LogRocket.getSessionURL(sessionURL => {
        this.sessionURL = sessionURL;
        Sentry.configureScope(scope => {
          scope.setTag("sessionURL", sessionURL);
        });
      });
    }

    app.firestore.setLogLevel(getFirestoreLogLevel());
    this.fieldValue = app.firestore.FieldValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    this.auth = app.auth();
    this.db = app.firestore();
    this.db.settings({
      ignoreUndefinedProperties: true,
      experimentalAutoDetectLongPolling: true
    });
    this.storage = app.storage();
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.microsoftProvider = new app.auth.OAuthProvider("microsoft.com");
    this.remoteConfig = app.remoteConfig();
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithMicrosoft = () =>
    this.auth.signInWithPopup(this.microsoftProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  updateDisplayName = (uid, displayName) =>
    Promise.all([
      this.auth.currentUser.updateProfile({ displayName }),
      this.user(uid).set({ name: displayName }, { merge: true })
    ]);

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: import.meta.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT
    });

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  onAuthUserListener = (next, fallback, userProcessing) => {
    let unsubscribeUserListener = null;
    const authStateListener = this.auth.onAuthStateChanged(async authUser => {
      if (!authUser) {
        if (unsubscribeUserListener) {
          unsubscribeUserListener();
        }
        Sentry.configureScope(scope => scope.setUser(null));
        fallback();
        return;
      }

      Sentry.setUser({ email: authUser.email });

      const { uid, email, emailVerified, providerData } = authUser;

      unsubscribeUserListener = this.user(uid).onSnapshot(async snapshot => {
        const dbUser = snapshot.data();

        if (!dbUser) {
          fallback(NOTAUTHORIZED);
          return;
        }

        if (dbUser && !dbUser.roles) {
          dbUser.roles = {};
        }

        const { claims: { client: authUserClient, role: authUserRole } = {} } =
          await authUser.getIdTokenResult(true);

        const [rolesAsString] = Object.keys(dbUser?.roles);

        if (
          dbUser?.client !== authUserClient ||
          rolesAsString !== authUserRole
        ) {
          const success = await setCustomClaims(this.db, authUser);

          if (!success) {
            fallback(NOTAUTHORIZED);
            return;
          }

          await authUser.reload();
        }

        if (!dbUser?.client && !userProcessing) {
          fallback(NOTAUTHORIZED);
          return;
        }

        const mergedUserData = {
          uid,
          email,
          emailVerified,
          providerData,
          ...dbUser
        };

        if (shouldInitializeLogRocket()) {
          LogRocket.identify(uid, {
            name: mergedUserData.name,
            email,
            emailVerified,
            roles: mergedUserData.roles,
            providerData
          });
        }
        next(mergedUserData);
      }, console.error);
    });

    return () => {
      if (unsubscribeUserListener) {
        unsubscribeUserListener();
      }
      Sentry.configureScope(scope => scope.setUser(null));
      authStateListener();
    };
  };

  user = uid => this.db.doc(`users/${uid}`);

  users = () => this.db.collection("users");
}

export default Firebase;
